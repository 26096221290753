import { ChangeDetectionStrategy, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { SplashScreenService } from 'src/app/core/services';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-splash-screen',
  styleUrls: ['./splash-screen.component.scss'],
  templateUrl: './splash-screen.component.html',
})
export class SplashScreenComponent implements OnInit {
  @ViewChild('splashScreen', { static: true }) public splashScreen: ElementRef;

  constructor(private splashScreenService: SplashScreenService) {}

  public ngOnInit(): void {
    this.splashScreenService.init(this.splashScreen);
  }
}
