import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';

import { SectionHeaderComponent } from '.';
import { BaseComponent } from './base/base.component';
import { CommentDialogComponent } from './comment-dialog/comment-dialog.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { HeaderComponent } from './header/header.component';
import { MenuComponent } from './header/menu/menu.component';
import { MobileNavigationComponent } from './header/mobile-navigation/mobile-navigation.component';
import { SidebarMenuComponent } from './sidebar/sidebar-menu/sidebar-menu.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { SplashScreenComponent } from './splash-screen/splash-screen.component';
import { SubHeaderComponent } from './sub-header/sub-header.component';
import { SectionCommentsComponent } from './section-comments/section-comments.component';

import { MatBadgeModule } from '@angular/material/badge';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { CommentsService } from './section-comments/section-comments.service';
import { CoreModule } from '@core/core.module';
import { MessageDialogComponent } from './message-dialog/message-dialog.component';
import { SaveCancelButtonComponent } from './save-cancel-button/save-cancel-button.component';

@NgModule({
  declarations: [
    BaseComponent,
    SplashScreenComponent,
    HeaderComponent,
    MenuComponent,
    SidebarComponent,
    SidebarMenuComponent,
    MobileNavigationComponent,
    SubHeaderComponent,
    SectionHeaderComponent,
    ConfirmationDialogComponent,
    CommentDialogComponent,
    SectionCommentsComponent,
    MessageDialogComponent,
    SaveCancelButtonComponent,
  ],
  exports: [
    BaseComponent,
    SplashScreenComponent,
    HeaderComponent,
    MenuComponent,
    SidebarComponent,
    SidebarMenuComponent,
    MobileNavigationComponent,
    SubHeaderComponent,
    SectionHeaderComponent,
    ConfirmationDialogComponent,
    CommentDialogComponent,
    SectionCommentsComponent,
    SaveCancelButtonComponent,
  ],
  imports: [
    CoreModule,
    CommonModule,
    RouterModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatTooltipModule,
    MatBadgeModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatDividerModule,
    MatListModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  providers: [CommentsService],
})
export class ComponentsModule {}
