import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HubCompanyInterface, NormalOptionInterface, OptionInterface } from '@core/models';
import { Observable } from 'rxjs';
import { first, pluck } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  constructor(private http: HttpClient) {}

  public getApparelFabricTypesList(): Observable<OptionInterface[]> {
    const endpoint: string = `${environment.api_url}/fabric-types-group`;

    return this.http.get<OptionInterface[]>(endpoint).pipe(first(), pluck('data'));
  }

  public getCompaniesByString(search: string): Observable<HubCompanyInterface[]> {
    const endpoint = `${environment.api_url}/entities/search`;

    return this.http.post(endpoint, { name: search }).pipe(first(), pluck('data')) as Observable<HubCompanyInterface[]>;
  }

  public getCompositionList(): Observable<OptionInterface[]> {
    const endpoint: string = `${environment.api_url}/p/fabrics/materials`;

    return this.http.get<OptionInterface[]>(endpoint).pipe(first(), pluck('data'));
  }

  public getFabricTypes(): Observable<OptionInterface[]> {
    const endpoint: string = `${environment.api_url}/p/fabrics/fabric-types`;

    return this.http.get<OptionInterface[]>(endpoint).pipe(first(), pluck('data'));
  }

  public getFabricSubTypes(id: number): Observable<OptionInterface[]> {
    const endpoint: string = `${environment.api_url}/p/fabrics/fabric-types?id_group=${id}`;

    return this.http.get<OptionInterface[]>(endpoint).pipe(first(), pluck('data'));
  }

  public getFlagsList(): Observable<OptionInterface[]> {
    const endpoint: string = `${environment.api_url}/flags`;

    return this.http.get<OptionInterface[]>(endpoint).pipe(first(), pluck('data'));
  }

  public getManufacturerTypes(): Observable<OptionInterface[]> {
    const endpoint: string = `${environment.api_url}/p/manufacturers/types`;

    return this.http.get<OptionInterface[]>(endpoint).pipe(first(), pluck('data'));
  }

  public getProductGroupList(): Observable<OptionInterface[]> {
    const endpoint: string = `${environment.api_url}/p/pg1`;

    return this.http.get<OptionInterface[]>(endpoint).pipe(first(), pluck('data'));
  }

  public getSegmentGroupList(): Observable<OptionInterface[]> {
    const endpoint: string = `${environment.api_url}/p/ct2`;

    return this.http.get<OptionInterface[]>(endpoint).pipe(first(), pluck('data'));
  }

  public getTargetGroupList(): Observable<OptionInterface[]> {
    const endpoint: string = `${environment.api_url}/p/ct3`;

    return this.http.get<OptionInterface[]>(endpoint).pipe(first(), pluck('data'));
  }

  public getCategoriesList(): Observable<NormalOptionInterface[]> {
    const endpoint: string = `${environment.api_url}/product/categories`;

    return this.http.get<NormalOptionInterface[]>(endpoint).pipe(first(), pluck('data'));
  }
}
