import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-comment-dialog',
  templateUrl: './comment-dialog.component.html',
})
export class CommentDialogComponent implements OnInit {
  @Input()
  public title: string = 'Add a comment';

  @Input()
  public btnConfirmText: string = 'Add comment';

  @Input()
  public btnCancelText: string = 'Cancel';

  @Output()
  public inputInfo: EventEmitter<string> = new EventEmitter();

  messageControl = new FormControl('');
  public form: FormGroup;

  constructor(private formBuilder: FormBuilder) {}

  public ngOnInit(): void {
    this.form = this.createForm();
  }

  public onConfirmClick(ev: MouseEvent): void {
    ev.preventDefault();

    const { value } = this.form;
    this.inputInfo.emit(value.comment);
  }

  private createForm(): FormGroup {
    return this.formBuilder.group({
      comment: [''],
    });
  }
}
