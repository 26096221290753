<div class="content-container flex-column align-items-start">
  <app-section-header title="Comments" icon="question_answer"></app-section-header>

  <form [formGroup]="commentsForm" (ngSubmit)="onCommentsFormSubmit()" autocomplete="off">
    <mat-form-field appearance="fill">
      <mat-label>Leave a comment</mat-label>
      <textarea
        formControlName="comment"
        matInput
        placeholder="Ex. It makes me feel..."
        rows="5"
        required
        maxlength="2000"
      ></textarea>
      <mat-error *ngIf="isControlHasError('comment', 'required')">
        <strong>This field is required.</strong>
      </mat-error>
    </mat-form-field>
    <button mat-raised-button [loading]="submittingComment$ | async" class="btn-submit">
      <mat-icon>add</mat-icon>
      Add comment
    </button>
  </form>

  <ng-container *ngIf="comments$ | async as comments; else loadingState">
    <mat-list class="comments-list w-100">
      <ng-container *ngFor="let commentItem of comments; let last = last">
        <mat-list-item>
          <mat-icon mat-list-icon>insert_comment</mat-icon>
          <h3 mat-line>{{ commentItem.created_by }}</h3>
          <small mat-line>{{ commentItem.created_at | date: 'dd/MM/YYYY HH:mm' }}</small>
          <span mat-line class="comment-message" [innerHTML]="commentItem.comment"></span>
        </mat-list-item>
        <mat-divider *ngIf="!last"></mat-divider>
      </ng-container>
    </mat-list>
  </ng-container>
</div>
<ng-template #loadingState> Loading... </ng-template>
