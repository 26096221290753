<div class="header fixed-top">
  <mat-progress-bar *ngIf="loadingService.isLoading$ | async" mode="indeterminate"></mat-progress-bar>

  <div class="container-fluid d-flex align-items-center justify-content-between header-container">
    <button mat-button class="d-lg-none" (click)="isMenuOpen = !isMenuOpen">
      <mat-icon>menu</mat-icon>
    </button>

    <app-mobile-navigation
      (closed)="isMenuOpen = false"
      [ngClass]="{ 'd-none': !isMenuOpen }"
      class="animate__animated animate__faster animate__slideInLeft"
    ></app-mobile-navigation>

    <div class="d-flex">
      <app-menu class="d-none d-lg-block"></app-menu>
    </div>

    <button mat-button [matMenuTriggerFor]="userMenu" (click)="$event.stopImmediatePropagation()">
      {{ userInfo?.name }}
      <mat-icon class="ms-xs">account_circle</mat-icon>
    </button>

    <mat-menu #userMenu="matMenu" xPosition="before" class="user-menu" (click)="$event.stopImmediatePropagation()">
      <a *ngIf="resources.settings" mat-menu-item routerLink="/settings">
        <mat-icon>settings</mat-icon>
        Settings
      </a>
      <button *ngIf="resources.userManagement" mat-menu-item (click)="onUserManagementClick($event)">
        <mat-icon>supervisor_account</mat-icon>
        User Management
      </button>
      <button mat-menu-item (click)="onLogout($event)">
        <mat-icon>logout</mat-icon>
        Logout
      </button>
    </mat-menu>
  </div>
</div>
