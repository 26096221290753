<div class="d-flex justify-content-end mt-4">
  <button mat-raised-button (click)="onCancelClick($event)" data-qa-id="cancel-button">
    <mat-icon>chevron_left</mat-icon>
    Cancel
  </button>

  <button mat-raised-button color="primary" class="ms-2" type="submit" data-qa-id="save-button">
    <mat-icon>done</mat-icon>
    Save changes
  </button>
</div>
