import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-sub-header',
  styleUrls: ['./sub-header.component.scss'],
  templateUrl: './sub-header.component.html',
})
export class SubHeaderComponent {
  @Input()
  public title: string;

  @Input()
  public subtitle: string;
}
