import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { UserAuthInterface } from '@core/models';
import { AppState, selectAuthState } from '@core/store/app.states';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { first, pluck } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PasswordGuard implements CanActivate, CanActivateChild {
  constructor(
    private router: Router,
    private store: Store<AppState>
  ) {}

  public canActivate(route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<boolean> | UrlTree {
    return this.canActivateChild(route, _state);
  }

  public canActivateChild(route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<boolean> | UrlTree {
    this.store
      .select(selectAuthState)
      .pipe(first(), pluck('user'))
      .subscribe({
        next: (userInfo: UserAuthInterface) => {
          if (userInfo.must_change_pass) {
            this.router.navigate(['/password']);

            return of(false);
          }
        },
      });

    return of(true);
  }
}
