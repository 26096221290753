import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'headquarterType',
})
export class HeadquarterTypePipe implements PipeTransform {
  transform(arr: string[], type: string): boolean {
    return arr.includes(type);
  }
}
