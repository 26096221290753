<h2 mat-dialog-title>{{ title }}</h2>
<mat-dialog-content class="mat-typography">
  <form [formGroup]="form">
    <mat-form-field appearance="fill" class="w-100">
      <mat-label>Add comment</mat-label>
      <textarea formControlName="comment" matInput placeholder="Add a comment" rows="5"></textarea>
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button mat-dialog-close>{{ btnCancelText }}</button>
  <button mat-raised-button color="primary" (click)="onConfirmClick($event)">
    {{ btnConfirmText }}
  </button>
</mat-dialog-actions>
