import { UserAuthInterface } from '@core/models';
import { All, AuthActionTypes } from '@core/store/actions/auth.actions';

export interface State {
  isAuthenticated: boolean;
  user: UserAuthInterface | null;
}

export const initialState: State = {
  isAuthenticated: false,
  user: null,
};

export function reducer(state: State = initialState, action: All): State {
  switch (action.type) {
    case AuthActionTypes.AUTHENTICATE: {
      return {
        ...state,
        isAuthenticated: action.payload,
      };
    }

    case AuthActionTypes.SESSION: {
      return {
        ...state,
        user: action.payload,
      };
    }

    default: {
      return state;
    }
  }
}
