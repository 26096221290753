import { animate, AnimationBuilder, AnimationPlayer, style } from '@angular/animations';
import { ElementRef, Injectable } from '@angular/core';

@Injectable()
export class SplashScreenService {
  private el: ElementRef;
  private stopped: boolean;

  constructor(private animationBuilder: AnimationBuilder) {}

  public hide(): void {
    if (this.stopped) {
      return;
    }

    const player: AnimationPlayer = this.animationBuilder
      .build([style({ opacity: '1' }), animate(800, style({ opacity: '0' }))])
      .create(this.el.nativeElement);

    player.onDone(() => {
      if (typeof this.el.nativeElement.remove === 'function') {
        this.el.nativeElement.remove();
      } else {
        this.el.nativeElement.style.display = 'none';
      }
      this.stopped = true;
    });

    setTimeout(() => player.play(), 300);
  }

  public init(element: ElementRef): void {
    this.el = element;
  }
}
