import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ComponentsModule } from '@components/components.module';
import { AuthInterceptor, DomainInterceptor, ErrorInterceptor, LoadingInterceptor } from '@core/interceptors';
import { SplashScreenService } from '@core/services';
import { reducers } from '@core/store/app.states';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

@NgModule({
  bootstrap: [AppComponent],
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ComponentsModule,
    MatSnackBarModule,
    HttpClientModule,
    StoreModule.forRoot(reducers, {}),
    StoreRouterConnectingModule.forRoot({ stateKey: 'router' }),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
  ],
  providers: [
    SplashScreenService,
    CookieService,
    { multi: true, provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor },
    { multi: true, provide: HTTP_INTERCEPTORS, useClass: DomainInterceptor },
    { multi: true, provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor },
    { multi: true, provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor },
  ],
})
export class AppModule {}
