import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-message-dialog',
  templateUrl: './message-dialog.component.html',
  styleUrls: [],
})
export class MessageDialogComponent {
  @Input()
  public title: string = 'Confirmation';

  @Input()
  public message: string = 'Are you sure?';

  @Input()
  public state: string;

  @Input()
  public reason: string;

  @Input()
  public btnConfirmText: string = 'Confirm';

  @Input()
  public btnCancelText: string = 'Cancel';
}
