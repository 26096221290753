import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-save-cancel-button',
  templateUrl: './save-cancel-button.component.html',
})
export class SaveCancelButtonComponent {
  @Input() public link: string;

  constructor(private router: Router) {}

  public onCancelClick(ev: MouseEvent): void {
    ev.preventDefault();

    this.router.navigate([this.link]);
  }
}
