import { Injectable } from '@angular/core';
import { AuthNoticeInterface } from '@core/models';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthNoticeService {
  public onNoticeChanged$: BehaviorSubject<AuthNoticeInterface>;

  constructor() {
    this.onNoticeChanged$ = new BehaviorSubject(null);
  }

  public reset(): void {
    const notice: AuthNoticeInterface = {
      message: null,
      type: null,
    };

    this.onNoticeChanged$.next(notice);
  }

  public setNotice(message: string, type: string): void {
    const notice: AuthNoticeInterface = {
      message,
      type,
    };

    this.onNoticeChanged$.next(notice);
  }
}
