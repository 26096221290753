<nav class="navbar navbar-expand-lg">
  <ul class="navbar-nav">
    <li *ngIf="resources.dashboard" class="nav-item" routerLinkActive="active">
      <a
        class="nav-link"
        routerLink="/dashboard"
        matTooltip="Dashboard"
        matTooltipPosition="right"
        matTooltipClass="side-tooltip"
      >
        <mat-icon>pie_chart_outline</mat-icon>
      </a>
    </li>

    <li *ngIf="resources.users" class="nav-item" routerLinkActive="active">
      <a
        class="nav-link"
        routerLink="/users"
        matTooltip="Users"
        matTooltipPosition="right"
        matTooltipClass="side-tooltip"
      >
        <mat-icon>people_alt</mat-icon>
      </a>
    </li>

    <li *ngIf="resources.entities" class="nav-item" routerLinkActive="active">
      <a
        class="nav-link"
        routerLink="/companies"
        matTooltip="Companies"
        matTooltipPosition="right"
        matTooltipClass="side-tooltip"
      >
        <mat-icon>work</mat-icon>
      </a>
    </li>

    <li *ngIf="resources.entities" class="nav-item" routerLinkActive="active">
      <a
        class="nav-link"
        routerLink="/import-entities"
        matTooltip="Import Companies"
        matTooltipPosition="right"
        matTooltipClass="side-tooltip"
      >
        <mat-icon>import_export</mat-icon>
      </a>
    </li>

    <li *ngIf="resources.payments" class="nav-item" routerLinkActive="active">
      <a
        class="nav-link"
        routerLink="/payments"
        matTooltip="Payments"
        matTooltipPosition="right"
        matTooltipClass="side-tooltip"
      >
        <mat-icon>payment</mat-icon>
      </a>
    </li>

    <li *ngIf="resources.certificate" class="nav-item" routerLinkActive="active">
      <a
        class="nav-link"
        routerLink="/certificates"
        matTooltip="Certificates"
        matTooltipPosition="right"
        matTooltipClass="side-tooltip"
      >
        <mat-icon>workspace_premium</mat-icon>
      </a>
    </li>

    <li *ngIf="resources.rfq" class="nav-item" routerLinkActive="active">
      <a
        class="nav-link"
        routerLink="/sourcing-projects"
        matTooltip="RFQs"
        matTooltipPosition="right"
        matTooltipClass="side-tooltip"
      >
        <mat-icon>request_quote</mat-icon>
      </a>
    </li>

    <li *ngIf="resources.products" class="nav-item" routerLinkActive="active">
      <a
        class="nav-link"
        routerLink="/products"
        matTooltip="Products"
        matTooltipPosition="right"
        matTooltipClass="side-tooltip"
      >
        <mat-icon>shop</mat-icon>
      </a>
    </li>

    <li *ngIf="resources.hubs" class="nav-item" routerLinkActive="active">
      <a
        class="nav-link"
        routerLink="/hubs"
        matTooltip="Hubs"
        matTooltipPosition="right"
        matTooltipClass="side-tooltip"
      >
        <mat-icon>share</mat-icon>
      </a>
    </li>

    <li *ngIf="resources.contacts" class="nav-item" routerLinkActive="active">
      <a
        class="nav-link"
        routerLink="/contacts"
        matTooltip="Contacts"
        matTooltipPosition="right"
        matTooltipClass="side-tooltip"
      >
        <mat-icon>contact_support</mat-icon>
      </a>
    </li>

    <li *ngIf="resources.profile_reports" class="nav-item" routerLinkActive="active">
      <a
        class="nav-link"
        routerLink="/profile-reports"
        matTooltip="Profile reports"
        matTooltipPosition="right"
        matTooltipClass="side-tooltip"
      >
        <mat-icon>portrait</mat-icon>
      </a>
    </li>

    <li *ngIf="resources.help_reports" class="nav-item" routerLinkActive="active">
      <a
        class="nav-link"
        routerLink="/help-reports"
        matTooltip="Help Reports"
        matTooltipPosition="right"
        matTooltipClass="side-tooltip"
      >
        <mat-icon>help</mat-icon>
      </a>
    </li>

    <li *ngIf="resources.leads" class="nav-item" routerLinkActive="active">
      <a
        class="nav-link"
        routerLink="/leads"
        matTooltip="Leads"
        matTooltipPosition="right"
        matTooltipClass="side-tooltip"
      >
        <mat-icon matBadge="OLD" matBadgePosition="below after" matBadgeColor="accent">filter_list</mat-icon>
      </a>
    </li>
  </ul>
</nav>
