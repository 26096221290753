<button mat-button class="btn-close" (click)="onCloseClick()">
  <mat-icon>close</mat-icon>
</button>

<nav class="navbar">
  <ul class="navbar-nav w-100">
    <li *ngIf="resources.dashboard" class="nav-item" routerLinkActive="active">
      <a class="nav-link" routerLink="/dashboard" (click)="onCloseClick()"> Dashboard </a>
    </li>
    <li
      *ngIf="resources.users"
      class="nav-item d-flex justify-content-center align-items-center"
      routerLinkActive="active"
    >
      <a class="nav-link" routerLink="/users" (click)="onCloseClick()"> Users </a>
    </li>
    <li *ngIf="resources.entities" class="nav-item" routerLinkActive="active">
      <a class="nav-link" routerLink="/companies" (click)="onCloseClick()"> Companies </a>
    </li>
    <li *ngIf="resources.entities" class="nav-item" routerLinkActive="active">
      <a class="nav-link" routerLink="/import-entities" (click)="onCloseClick()"> Import Companies </a>
    </li>
    <li *ngIf="resources.payments" class="nav-item" routerLinkActive="active">
      <a class="nav-link" routerLink="/payments" (click)="onCloseClick()"> Payments </a>
    </li>
    <li *ngIf="resources.certificate" class="nav-item" routerLinkActive="active">
      <a class="nav-link" routerLink="/certificates" (click)="onCloseClick()"> Certificates </a>
    </li>
    <li *ngIf="resources.rfq" class="nav-item" routerLinkActive="active">
      <a class="nav-link" routerLink="/sourcing-projects" (click)="onCloseClick()"> RFQs </a>
    </li>
    <li *ngIf="resources.hubs" class="nav-item" routerLinkActive="active">
      <a class="nav-link" routerLink="/hubs" (click)="onCloseClick()"> Hubs </a>
    </li>
    <li *ngIf="resources.contacts" class="nav-item" routerLinkActive="active">
      <a class="nav-link" routerLink="/contacts" (click)="onCloseClick()"> Contacts </a>
    </li>
    <li *ngIf="resources.profile" class="nav-item" routerLinkActive="active">
      <a class="nav-link" routerLink="/profile-reports" (click)="onCloseClick()"> Profile Reports </a>
    </li>
    <li *ngIf="resources.help_reports" class="nav-item" routerLinkActive="active">
      <a class="nav-link" routerLink="/help-reports" (click)="onCloseClick()"> Help Reports </a>
    </li>
    <li *ngIf="resources.leads" class="nav-item" routerLinkActive="active">
      <a class="nav-link" routerLink="/leads" (click)="onCloseClick()"> Leads <span class="new-badge">OLD</span></a>
    </li>
  </ul>
</nav>
