import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterBy',
})
export class FilterByPipe implements PipeTransform {
  public transform(obj: any[], field: string, value: any) {
    if (!obj || obj.length === 0 || Object.keys(obj[0]).indexOf(field) === -1) {
      return obj;
    }

    return obj.filter((item) => (Array.isArray(value) ? value.indexOf(item[field]) >= 0 : item[field] === value));
  }
}
