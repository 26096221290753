import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService, ConstantsService } from '@core/services';
import { Observable } from 'rxjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const accessToken: string = this.authService.getUserToken()?.access_token;

    // If Ignore Authorization (Refresh Token example) is set on headers, delete it and clone request
    if (request.headers.has(ConstantsService.IGNORE_AUTH_HEADER)) {
      const headers: HttpHeaders = request.headers.delete(ConstantsService.IGNORE_AUTH_HEADER);

      return next.handle(request.clone({ headers }));
    }

    // If access token is empty this means that user is not logged in, so we return original request
    if (!accessToken) {
      return next.handle(request);
    }

    // We clone the request, because the original request is immutable
    return next.handle(
      request.clone({
        setHeaders: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
    );
  }
}
