import { NgModule } from '@angular/core';

import { LoadingButtonDirective } from './directives';
import { FilterByPipe } from './pipes';
import { JoinPipe } from './pipes/join-pipe.pipe';
import { HeadquarterTypePipe } from './pipes/headquarter-type.pipe';
import { ArrayExistPipe } from './pipes/array-exist.pipe';

@NgModule({
  declarations: [LoadingButtonDirective, FilterByPipe, JoinPipe, HeadquarterTypePipe, ArrayExistPipe],
  exports: [LoadingButtonDirective, FilterByPipe, JoinPipe, HeadquarterTypePipe, ArrayExistPipe],
  imports: [],
})
export class CoreModule {}
