import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'arrayExist',
})
export class ArrayExistPipe implements PipeTransform {
  transform(array: any[]): boolean {
    return array && array.length > 0;
  }
}
