<h2 mat-dialog-title>{{ title }}</h2>
<mat-dialog-content class="mat-typography">
  <textarea
    (click)="$event.stopImmediatePropagation()"
    matInput
    [(ngModel)]="reason"
    placeholder="Ex. The reason for this is..."
    rows="5"
    required
    maxlength="2000"
  ></textarea>
  <mat-hint align="end">{{ reason.length || 0 }}/2000</mat-hint>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button mat-dialog-close>{{ btnCancelText }}</button>
  <button mat-raised-button [mat-dialog-close]="reason" color="primary" [disabled]="reason.length < 1">
    {{ btnConfirmText }}
  </button>
</mat-dialog-actions>
