import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResourceInterface, RolesResponseInterface } from '@core/models';
import { Observable, of } from 'rxjs';
import { first, pluck, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AclService {
  private resources: Map<string, boolean>;

  constructor(private http: HttpClient) {}

  public allow(resourceKey: string): boolean {
    const permission: boolean = this.resources.get(resourceKey.toLowerCase().trim());

    return !this.resources || permission === undefined ? false : (permission as boolean);
  }

  public fetchResourcesByKey(key: string): Observable<RolesResponseInterface[]> {
    const endpoint: string = `${environment.api_url}/user/me/resources/${key}`;

    return this.http.get<ResourceInterface[]>(endpoint).pipe(
      first(),
      pluck('data'),
      switchMap((response: RolesResponseInterface[]) => {
        response.map((acl) => {
          const mappedResources: Map<string, boolean> = this.resourcesMap(acl.resources);
          this.resources = mappedResources;
        });

        return of(response);
      })
    );
  }

  private resourcesMap(resources: ResourceInterface[]): Map<string, boolean> {
    const convert = (array: ResourceInterface[], size = 1) => {
      const rs: [string, boolean][] = [];
      let count: number = 0;

      while (array.length - count) {
        rs.push([array[count].resource, array[count].value]);
        count++;
      }

      return rs;
    };

    return new Map<string, boolean>(convert(resources));
  }
}
