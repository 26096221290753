import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AuthTokenInterface,
  ForgotPasswordInterface,
  LoginInterface,
  RefreshTokenReponseInterface,
  UpdatedResponseInterface,
} from '@core/models';
import { Observable } from 'rxjs';
import { first, pluck } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Md5 } from 'ts-md5';

import { ConstantsService } from './constants.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly LOCAL_STORAGE_KEY: string = 'user';

  constructor(private http: HttpClient) {}

  public authenticate(payload: LoginInterface): Observable<AuthTokenInterface> {
    const endpoint: string = environment.api_url + '/auth/get-token';
    const params: HttpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Domain', 'crm');

    return this.http
      .post<AuthTokenInterface>(endpoint, { ...payload, ...this.getParams() }, { headers: params })
      .pipe(first(), pluck('data'));
  }

  public getUserToken(): AuthTokenInterface {
    try {
      return JSON.parse(localStorage.getItem(this.LOCAL_STORAGE_KEY));
    } catch (e) {
      return null;
    }
  }

  public isAuthenticated(): boolean {
    try {
      return typeof localStorage.getItem(this.LOCAL_STORAGE_KEY) === 'string';
    } catch (e) {
      return false;
    }
  }

  public logout(): void {
    localStorage.removeItem(this.LOCAL_STORAGE_KEY);
  }

  public setToken(value: AuthTokenInterface): void {
    localStorage.setItem(this.LOCAL_STORAGE_KEY, JSON.stringify(value));
  }

  public refreshToken(): Observable<RefreshTokenReponseInterface> {
    const headers: HttpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set(ConstantsService.IGNORE_AUTH_HEADER, 'true')
      .set('Authorization', `Bearer ${this.getUserToken()?.refresh_token}`);

    const endpoint: string = `${environment.api_url}/auth/refresh-token`;

    return this.http.get<RefreshTokenReponseInterface>(endpoint, { headers }).pipe(first(), pluck('data'));
  }

  public updateStoredToken(token: string): void {
    let userData: AuthTokenInterface;

    try {
      userData = JSON.parse(localStorage.getItem(this.LOCAL_STORAGE_KEY));
    } catch {
      userData = null;
    }

    userData.access_token = token;

    localStorage.setItem(this.LOCAL_STORAGE_KEY, JSON.stringify(userData));
  }

  public forgotPassword(payload: ForgotPasswordInterface): Observable<UpdatedResponseInterface> {
    const endpoint: string = `${environment.api_url}/p/forgot-password`;

    return this.http.put<UpdatedResponseInterface>(endpoint, payload).pipe(first(), pluck('data'));
  }

  private getParams(): { time: number; hash: string | Int32Array } {
    const time: number = new Date(new Date().toUTCString()).getTime() / 1000;
    const hash: string = Md5.hashStr(`${environment.secret_salt}${time}`);

    return { time, hash };
  }
}
