import { UserAuthInterface } from '@core/models';
import { Action } from '@ngrx/store';

export enum AuthActionTypes {
  AUTHENTICATE = '[Auth] Authenticate',
  LOGOUT = '[Auth] Logout',
  SESSION = '[Auth] Session',
}

export class Authenticate implements Action {
  public readonly type: typeof AuthActionTypes.AUTHENTICATE = AuthActionTypes.AUTHENTICATE;
  constructor(public payload: boolean) {}
}

export class Session implements Action {
  public readonly type: typeof AuthActionTypes.SESSION = AuthActionTypes.SESSION;
  constructor(public payload: UserAuthInterface) {}
}

export type All = Authenticate | Session;
