import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserAuthInterface } from '@core/models';
import { AclService, AuthService } from '@core/services';
import { AppState, selectAuthState } from '@core/store/app.states';
import { Store } from '@ngrx/store';
import { first, pluck } from 'rxjs/operators';
import { LoadingService } from 'src/app/core/services/loading.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit {
  public isMenuOpen: boolean;
  public resources: {
    settings: boolean;
    userManagement: boolean;
  };
  public userInfo: UserAuthInterface;

  constructor(
    private aclService: AclService,
    private authService: AuthService,
    private router: Router,
    private store: Store<AppState>,
    public loadingService: LoadingService
  ) {
    this.resources = {
      settings: this.aclService.allow('crm.settings.permissions.read'),
      userManagement: this.aclService.allow('crm.user-management.permissions.read'),
    };
  }

  public ngOnInit(): void {
    this.store
      .select(selectAuthState)
      .pipe(first(), pluck('user'))
      .subscribe({
        next: (userInfo: UserAuthInterface) => {
          this.userInfo = userInfo;
        },
      });
  }

  public onLogout(ev: MouseEvent): void {
    ev.preventDefault();

    this.authService.logout();
    this.router.navigateByUrl('/auth/login');
  }

  public onUserManagementClick(ev: MouseEvent): void {
    ev.preventDefault();

    this.router.navigateByUrl('/user-management');
  }
}
