import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CommentInterface } from '@core/models';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';

import { CommentsService } from './section-comments.service';

@Component({
  selector: 'app-section-comments',
  styleUrls: ['./section-comments.component.scss'],
  templateUrl: './section-comments.component.html',
})
export class SectionCommentsComponent implements OnInit, OnDestroy {
  @Input() public path: string;
  @Input() public id: number;

  public submittingComment$: BehaviorSubject<boolean>;
  public comments$: Observable<CommentInterface[]>;
  public commentsForm: FormGroup;

  @ViewChild(FormGroupDirective) private commentsFormDirective: FormGroupDirective;

  private destroyer$: Subject<void>;
  private commentsPage$: BehaviorSubject<number>;

  constructor(
    private formBuilder: FormBuilder,
    public commentsService: CommentsService,
    private snackBar: MatSnackBar
  ) {
    this.destroyer$ = new Subject();
    this.commentsPage$ = new BehaviorSubject(1);
    this.submittingComment$ = new BehaviorSubject(false);
  }

  public ngOnInit(): void {
    this.initCommentsForm();

    this.comments$ = this.commentsPage$.pipe(
      takeUntil(this.destroyer$),
      switchMap(() => this.commentsService.getComments(this.id, this.path))
    );
  }

  public ngOnDestroy(): void {
    this.destroyer$.next();
    this.destroyer$.complete();
    this.destroyer$.unsubscribe();
  }

  public isControlHasError(controlName: string, validationType: string): boolean {
    const control: AbstractControl = this.commentsForm.controls[controlName];
    if (!control) {
      return false;
    }

    return control.hasError(validationType) && (control.dirty || control.touched);
  }

  public onCommentsFormSubmit(): void {
    if (this.commentsForm.invalid) {
      this.commentsForm.markAllAsTouched();

      return;
    }

    this.submittingComment$.next(true);

    this.commentsService
      .addComment(this.id, this.commentsForm.value, this.path)
      .pipe(takeUntil(this.destroyer$))
      .subscribe({
        error: (errorResponse: HttpErrorResponse) => {
          this.submittingComment$.next(false);
          this.snackBar.open(errorResponse.error.message || 'An error occurred, please try again later.', 'Dismiss');
        },
        next: () => {
          this.snackBar.open('Comment added successfully!', 'Dismiss');
          this.submittingComment$.next(false);
          this.commentsPage$.next(1);
          this.commentsForm.reset();
          this.commentsFormDirective.resetForm();
        },
      });
  }

  private initCommentsForm(): void {
    this.commentsForm = this.formBuilder.group({
      comment: ['', [Validators.required, Validators.maxLength(2000)]],
    });
  }
}
