import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserSessionResponseInterface } from '@core/models';
import { Observable } from 'rxjs';
import { first, pluck } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private http: HttpClient) {}

  public getUserSession(): Observable<UserSessionResponseInterface> {
    const endpoint: string = environment.api_url + '/users/sessions/me';

    return this.http.get<UserSessionResponseInterface>(endpoint).pipe(first(), pluck('data'));
  }
}
