import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { UserSessionResponseInterface } from '@core/models';
import { AuthService, UserService } from '@core/services';
import { Session } from '@core/store/actions/auth.actions';
import { AppState } from '@core/store/app.states';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, flatMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SessionGuard implements CanActivate, CanActivateChild {
  constructor(
    private authService: AuthService,
    private router: Router,
    private store: Store<AppState>,
    private userService: UserService
  ) {}

  public canActivate(route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<boolean> | UrlTree {
    return this.canActivateChild(route, _state);
  }

  public canActivateChild(route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<boolean> | UrlTree {
    return this.userService.getUserSession().pipe(
      flatMap((response: UserSessionResponseInterface) => {
        this.store.dispatch(
          new Session({ id: response.id, name: response.name, must_change_pass: response.must_change_pass })
        );

        return of(true);
      }),
      catchError((error: HttpErrorResponse) => {
        this.authService.logout();
        this.router.navigateByUrl('/auth/login');

        return of(false);
      })
    );
  }
}
