import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AclService } from '@core/services';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-sidebar-menu',
  styleUrls: ['./sidebar-menu.component.scss'],
  templateUrl: './sidebar-menu.component.html',
})
export class SidebarMenuComponent {
  public resources: {
    dashboard: boolean;
    leads: boolean;
    payments: boolean;
    certificate: boolean;
    rfq: boolean;
    profile_reports: boolean;
    help_reports: boolean;
    users: boolean;
    entities: boolean;
    hubs: boolean;
    contacts: boolean;
    products: boolean;
  };

  constructor(private aclService: AclService) {
    this.resources = {
      dashboard: this.aclService.allow('crm.dashboard.permissions.read'),
      leads: this.aclService.allow('crm.leads.permissions.read'),
      payments: this.aclService.allow('crm.payments.permissions.read'),
      certificate: this.aclService.allow('crm.certificate.permissions.read'),
      rfq: this.aclService.allow('crm.rfq-validation.permissions.read'),
      profile_reports: this.aclService.allow('crm.profile-reports.permissions.read'),
      help_reports: this.aclService.allow('crm.help-reports.permissions.read'),
      users: this.aclService.allow('crm.users.permissions.read'),
      entities: this.aclService.allow('crm.entities.permissions.read'),
      hubs: this.aclService.allow('crm.hubs.permissions.read'),
      contacts: this.aclService.allow('crm.contacts.permissions.read'),
      products: this.aclService.allow('crm.products.permissions.read'),
    };
  }
}
