import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { LoadingService } from 'src/app/core/services/loading.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-base',
  styleUrls: ['./base.component.scss'],
  templateUrl: './base.component.html',
})
export class BaseComponent implements OnInit {
  constructor(
    private router: Router,
    private loadingService: LoadingService
  ) {}

  public ngOnInit(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.loadingService.start();
      }

      if (event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError) {
        this.loadingService.stop();
      }
    });
  }
}
