import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AclService, AuthService } from '@core/services';
import { Observable, of } from 'rxjs';
import { catchError, first, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AclGuard implements CanActivate {
  constructor(
    private aclService: AclService,
    private authService: AuthService,
    private router: Router
  ) {}

  public canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.aclService.fetchResourcesByKey(next.data.routeID).pipe(
      first(),
      switchMap(() => {
        const hasPermission: boolean = this.aclService.allow(`crm.${next.data.routeID}.permissions.read`);

        if (!hasPermission) {
          this.router.navigate(['/error']);
        }

        return of(hasPermission);
      }),
      catchError(() => {
        this.router.navigate(['/error']);

        return of(false);
      })
    );
  }
}
