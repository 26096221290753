<div class="h-100">
  <div class="app-wrapper">
    <app-sidebar class="d-none d-lg-flex"></app-sidebar>
    <app-header></app-header>

    <div class="container">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
