import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { RefreshTokenReponseInterface } from '@core/models';
import { AuthService } from '@core/services';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, mergeMap, switchMap, take } from 'rxjs/operators';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  private isRefreshTokenInProgress: boolean;

  // Tracks the current token, or null if no token is currently available (e.g. refresh pending)
  private refreshTokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(
    private authService: AuthService,
    private router: Router
  ) {}

  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((httpError: HttpErrorResponse) => {
        switch (httpError.status) {
          case 401:
            return this.handleUnauthorizedError(request, next);

          default:
            break;
        }

        return throwError(httpError);
      })
    );
  }

  private handleUnauthorizedError(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // If isRefreshTokenInProgress is true, we will wait until refreshTokenSubject has a non-null value
    // (which means the new token is ready and we can retry the request again)
    if (this.isRefreshTokenInProgress) {
      return this.refreshTokenSubject.pipe(
        filter((token) => token !== null),
        take(1),
        switchMap((token: string) => {
          this.authService.updateStoredToken(token);

          return next.handle(request);
        })
      );
    } else {
      this.isRefreshTokenInProgress = true;

      // Set the refreshTokenSubject to null so that subsequent API calls will wait until the new token has been retrieved
      this.refreshTokenSubject.next(null);

      return this.authService.refreshToken().pipe(
        mergeMap((response: RefreshTokenReponseInterface) => {
          this.isRefreshTokenInProgress = false;
          this.refreshTokenSubject.next(response.access_token);

          this.authService.updateStoredToken(response.access_token);

          return next.handle(request);
        }),
        catchError((error: HttpErrorResponse) => {
          this.isRefreshTokenInProgress = false;

          this.authService.logout();
          this.router.navigateByUrl('auth/login');

          return throwError(error);
        })
      );
    }
  }
}
