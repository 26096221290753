import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-confirmation-dialog',
  styleUrls: ['./confirmation-dialog.component.scss'],
  templateUrl: './confirmation-dialog.component.html',
})
export class ConfirmationDialogComponent {
  @Input()
  public title: string = 'Confirmation';

  @Input()
  public message: string = 'Are you sure ?';

  @Input()
  public note: string;

  @Input()
  public btnConfirmText: string = 'Confirm';

  @Input()
  public btnCancelText: string = 'Cancel';
}
