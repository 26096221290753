import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ConstantsService {
  public static IGNORE_AUTH_HEADER: string = 'IgnoreAuthorization';
  public static PASSWORD_REGEX: string =
    '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-.,~^|+"/\\\\_´`<>;:= (){}\\[\\]\']).{10,}$';
}
