import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-section-header',
  styleUrls: ['./section-header.component.scss'],
  templateUrl: './section-header.component.html',
})
export class SectionHeaderComponent {
  @Input()
  public title: string;

  @Input()
  public icon: string;
}
