import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CommentInterface, InsertedResponseInterface } from '@core/models';

import { Observable } from 'rxjs';
import { first, pluck } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable()
export class CommentsService {
  constructor(private http: HttpClient) {}

  public getComments(id: number, key: string): Observable<CommentInterface[]> {
    const endpoint: string = `${environment.api_url}/${key}/${id}/comments`;

    return this.http.get<CommentInterface[]>(endpoint).pipe(first(), pluck('data'));
  }

  public addComment(id: number, payload: { comment: string }, key: string): Observable<InsertedResponseInterface> {
    const endpoint: string = `${environment.api_url}/${key}/${id}/comments`;

    return this.http.post<InsertedResponseInterface>(endpoint, payload).pipe(first(), pluck('data'));
  }
}
