import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BaseComponent } from '@components/base/base.component';
import { AclGuard, AuthGuard, PasswordGuard, SessionGuard } from '@core/guards';

const routes: Routes = [
  { path: 'auth', loadChildren: () => import('./modules/auth/auth.module').then((m) => m.AuthModule) },
  {
    path: '',
    component: BaseComponent,
    canActivate: [AuthGuard, SessionGuard],
    children: [
      {
        path: 'dashboard',
        canActivate: [AclGuard, PasswordGuard],
        data: { routeID: 'dashboard' },
        loadChildren: () => import('./modules/dashboard/dashboard.module').then((m) => m.DashboardModule),
      },
      {
        path: 'leads',
        canActivate: [AclGuard, PasswordGuard],
        data: { routeID: 'leads' },
        loadChildren: () => import('./modules/leads/leads.module').then((m) => m.LeadsModule),
      },
      {
        path: 'users',
        canActivate: [AclGuard, PasswordGuard],
        data: { routeID: 'users' },
        loadChildren: () => import('./modules/users/users.module').then((m) => m.UsersModule),
      },
      {
        path: 'companies',
        canActivate: [AclGuard, PasswordGuard],
        data: { routeID: 'entities' },
        loadChildren: () => import('./modules/companies/companies.module').then((m) => m.CompaniesModule),
      },
      {
        path: 'import-entities',
        canActivate: [AclGuard, PasswordGuard],
        data: { routeID: 'entities' },
        loadChildren: () =>
          import('./modules/import-entities/import-entities.module').then((m) => m.ImportEntitiesModule),
      },
      {
        path: 'sourcing-projects',
        canActivate: [AclGuard, PasswordGuard],
        data: { routeID: 'entities' },
        loadChildren: () =>
          import('./modules/sourcing-projects/sourcing-projects.module').then((m) => m.SourcingProjectsModule),
      },
      {
        path: 'payments',
        canActivate: [AclGuard, PasswordGuard],
        data: { routeID: 'payments' },
        loadChildren: () => import('./modules/payments/payments.module').then((m) => m.PaymentsModule),
      },
      {
        path: 'certificates',
        canActivate: [AclGuard, PasswordGuard],
        data: { routeID: 'certificate' },
        loadChildren: () => import('./modules/certificates/certificates.module').then((m) => m.CertificatesModule),
      },
      {
        path: 'user-management',
        canActivate: [AclGuard, PasswordGuard],
        data: { routeID: 'user-management' },
        loadChildren: () =>
          import('./modules/user-management/user-management.module').then((m) => m.UserManagementModule),
      },
      /*       {
        path: 'rfq-validation',
        canActivate: [AclGuard, PasswordGuard],
        data: { routeID: 'rfq-validation' },
        loadChildren: () => import('./modules/rfqs/rfqs.module').then((m) => m.RfqsModule),
      }, */
      {
        path: 'help-reports',
        canActivate: [AclGuard, PasswordGuard],
        data: { routeID: 'help-reports' },
        loadChildren: () => import('./modules/help-reports/help-reports.module').then((m) => m.HelpReportsModule),
      },
      {
        path: 'profile-reports',
        canActivate: [AclGuard, PasswordGuard],
        data: { routeID: 'profile-reports' },
        loadChildren: () =>
          import('./modules/profile-reports/profile-reports.module').then((m) => m.ProfileReportsModule),
      },
      {
        path: 'contacts',
        canActivate: [AclGuard, PasswordGuard],
        data: { routeID: 'contacts' },
        loadChildren: () => import('./modules/contacts/contacts.module').then((m) => m.ContactsModule),
      },
      {
        path: 'hubs',
        canActivate: [AclGuard, PasswordGuard],
        data: { routeID: 'hubs' },
        loadChildren: () => import('./modules/hubs/hubs.module').then((m) => m.HubsModule),
      },
      {
        path: 'settings',
        canActivate: [AclGuard, PasswordGuard],
        data: { routeID: 'settings' },
        loadChildren: () => import('./modules/settings/settings.module').then((m) => m.SettingsModule),
      },
      {
        path: 'password',
        canActivate: [AclGuard],
        data: { routeID: 'settings' },
        loadChildren: () => import('./modules/password/password.module').then((m) => m.PasswordModule),
      },
      {
        path: 'products',
        canActivate: [AclGuard, PasswordGuard],
        data: { routeID: 'products' },
        loadChildren: () => import('./modules/products/products.module').then((m) => m.ProductsModule),
      },
      {
        path: 'error',
        loadChildren: () => import('./modules/error/error.module').then((m) => m.ErrorModule),
      },
      { path: '', pathMatch: 'full', redirectTo: 'dashboard' },
      { path: '**', pathMatch: 'full', redirectTo: 'dashboard' },
    ],
  },
];

@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
})
export class AppRoutingModule {}
