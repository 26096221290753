<h2 mat-dialog-title>{{ title }}</h2>
<mat-dialog-content class="mat-typography">
  <p [innerHTML]="message"></p>
  <p class="note" *ngIf="note">{{ note }}</p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button mat-dialog-close>{{ btnCancelText }}</button>
  <button mat-raised-button [mat-dialog-close]="true" color="primary">
    {{ btnConfirmText }}
  </button>
</mat-dialog-actions>
