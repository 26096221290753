import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AclService } from '@core/services';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-menu',
  styleUrls: ['./menu.component.scss'],
  templateUrl: './menu.component.html',
})
export class MenuComponent {
  public resources: {
    dashboard: boolean;
  };

  constructor(private aclService: AclService) {
    this.resources = {
      dashboard: this.aclService.allow('crm.dashboard.permissions.read'),
    };
  }
}
